.write-blog-section {
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .blog-form {
    max-width: 500px;
    margin: auto;
  }
  
  .blog-form label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .blog-form input[type="text"],
  .blog-form select,
  .blog-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .blog-form textarea {
    resize: vertical;
  }
  
  .blog-form button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .blog-form button[type="submit"]:hover {
    background-color: #0056b3;
  }
  